export const EventTableContentRoadmap = [
  {
    id: "#operations-tag-camera_activation",
    name: "Camera Activation"
  },
  {
    id: "#operations-tag-camera_mode",
    name: "Camera Mode"
  },
  {
    id: "#operations-tag-camera_telemetry",
    name: "Camera Telemetry"
  },
  {
    id: "#operations-tag-clips",
    name: "Clips"
  },
  {
    id: "#operations-tag-diagnostics",
    name: "Diagnostics"
  },
  {
    id: "#operations-tag-event",
    name: "Event"
  },
  {
    id: "#operations-tag-history",
    name: "History"
  },
  {
    id: "#operations-tag-settings",
    name: "Settings"
  },
  {
    id: "#operations-tag-state",
    name: "State"
  },
  {
    id: "#operations-tag-system",
    name: "System"
  },
  {
    id: "#operations-tag-trip",
    name: "Trip"
  },
  {
    id: "#model-camera-activation",
    name: "Schemas"
  }
]